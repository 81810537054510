#end_date-label,
#start_date-label,
#name_campaign-label {
  padding: 0em 1em;
}

.swal-custom-container {
  margin-top: 15vh!important;
  margin-right: 1.5vw!important;
}

.swal-custom-container .swal2-popup {
  background-color: rgb(208, 205, 205);
}
